import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-dialog-share-agent',
  templateUrl: './dialog-share-agent.component.html',
  styleUrls: ['./dialog-share-agent.component.scss']
})
export class DialogShareAgentComponent implements OnInit {
  agentID: any;
  name: string;

  constructor(protected dialogRef: NbDialogRef<DialogShareAgentComponent>,private dialogService: NbDialogService, private afs: AngularFirestore) { 

  }

  


  shareAgent(agentID,email){
    console.log(agentID+ " Shared");
    var userID = "";    
    
    var userSearchQuery = this.afs.collection('users', ref => ref.where('email','==',email)).valueChanges().subscribe(docs=>{
      userSearchQuery.unsubscribe();
      
      if(!docs[0]){
        return this.createInvitation(agentID, email);
      }else{
        userID = docs[0]['uid'];
        var agentQuery = this.afs.collection('agent', ref => ref.where('AgentID','==',agentID)).valueChanges().subscribe(docs2=>{
          agentQuery.unsubscribe();
          var currentData = (docs2[0]);
          if(currentData['authorizedUsers']){
            currentData['authorizedUsers'].push(userID);
          }else{
            currentData['authorizedUsers'] = [userID];
          }
          this.afs.doc('agent/'+agentID).update(currentData).then(doc=>{
            this.closeDialog();
          });
        });
      }
    });

  }

  createInvitation(agentID, email){
    console.log(agentID,email);
    var invitationQuery = this.afs.collection('invitation', ref => ref.where('email','==',email)).valueChanges().subscribe(invitation=>{
      invitationQuery.unsubscribe();
      var currentData = (invitation[0]);
      if(currentData){

        //invitation already exists. Add the new agent to the existing
        
        if(currentData['agents']){
          currentData['agents'].push(agentID);
        }else{
          currentData['agents'] = [agentID];
        }

        this.afs.doc('invitation/'+email).update(currentData).then(doc=>{
          this.closeDialog();
        });
      }else{
        this.afs.doc('invitation/'+email).set({
          email: email,
          agents: [agentID]
        }).then(doc=>{
          this.closeDialog();
        });
      }
      
      
    });
  }

  ngOnInit() {
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
