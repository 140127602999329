import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomCellComparatorsService {

  constructor() { }

  //Custom Comparators
  freeSpaceComparator(fSpace1, fSpace2) { 


    if (fSpace1===undefined && fSpace2===undefined) { 
      return 0; 
    } 
    if (fSpace1===undefined) { 
      return -1; 
    } 
    if (fSpace2===undefined) { 
      return 1; 
    }
    var fSpace1Number = Number(fSpace1.split(' ')[0]); 
    var fSpace2Number = Number(fSpace2.split(' ')[0]);

    return fSpace1Number - fSpace2Number;
  }

  cpuPeriodComparator(cPeriod1, cPeriod2) { 


    if (cPeriod1===undefined && cPeriod2===undefined) { 
      return 0; 
    } 
    if (cPeriod1===undefined) { 
      return -1; 
    } 
    if (cPeriod2===undefined) { 
      return 1; 
    }
    var cPeriod1Number = Number(cPeriod1); 
    var cPeriodNumber = Number(cPeriod2);

    return cPeriod1Number - cPeriodNumber;
  }

  osLastUpdatedComparator(oslu1, olsu2){
    if (oslu1===undefined && olsu2===undefined) { 
      return 0; 
    } 
    if (oslu1===undefined) { 
      return -1; 
    } 
    if (olsu2===undefined) { 
      return 1; 
    }
    var oslu1Number = Number(new Date(oslu1).getTime()); 
    var oslu2Number = Number(new Date(olsu2).getTime());

    return oslu1Number - oslu2Number;
  
  }

  osBootTimeComparator(oslu1, olsu2){
    if (oslu1===undefined && olsu2===undefined) { 
      return 0; 
    } 
    if (oslu1===undefined) { 
      return -1; 
    } 
    if (olsu2===undefined) { 
      return 1; 
    }
    var oslu1Number = Number(new Date(oslu1).getTime()); 
    var oslu2Number = Number(new Date(olsu2).getTime());

    return oslu1Number - oslu2Number;
  
  }

  minutesComparator(time1, time2){
    
    if (time1===undefined && time2===undefined) { 
      return 0; 
    } 
    if (time1===undefined) { 
      return -1; 
    } 
    if (time2===undefined) { 
      return 1; 
    }
    var time1Number = Number(time1.split(' ')[0]); 
    var time2Number = Number(time2.split(' ')[0]);

    return time1Number - time2Number;
  }
}
