import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  settings = {
    actions: false,
    hideSubHeader: true,
    pager: {
      display: true,
      perPage: 100
    },
    columns: {
      AgentID: {
        title: 'AgentID', filter: false
      },
      AgentLastReported: {
        title: 'AgentLastReported', filter: false
      },
      TimeSinceCalledHome: {
        title: 'TimeSinceCalledHome', filter: false
      },
      HostName: {
        title: 'HostName', filter: false
      }
    }
  };

  data = [];
  constructor(private afs: AngularFirestore, private http: HttpClient) {
    
   }

   private message = {
    author: "tutorialedge",
    message: "this is a test message"
  };

  sendMsg() {
    console.log("new message from client to websocket: ", this.message);
    
  }

  ngOnInit() {
    this.afs.collection('agent', ref => 
    ref.orderBy('AgentLastReported','asc')).valueChanges().subscribe(docs=>{
      this.data = docs;
      for(let i =0; i < this.data.length; i++){
        this.data[i].AgentLastReported = this.data[i].AgentLastReported.toDate();
        this.data[i].HostName = this.data[i].HostDataPoints['HostName'];
        const diffInMilliseconds = new Date().getTime() - this.data[i].AgentLastReported.getTime(); // 10800000
        this.data[i].TimeSinceCalledHome = (diffInMilliseconds/60000).toFixed()+' min';

      }
    });
  }


}
