import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-share-button',
  template: `<span><button style="height: 20px" (click)="invokeParentMethod()" class="btn btn-info">Share Item</button></span>`,
  styles: [
      `.btn {
          line-height: 0.5
      }`
  ]
})
export class ShareButtonComponent implements ICellRendererAngularComp {

  public params: any;

  agInit(params: any): void {
      this.params = params;
  }

  public invokeParentMethod() {
      this.params.context.componentParent.shareItem(this.params.node.data.agentID);
  }

  refresh(): boolean {
      return false;
  }

}
