import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment'; 
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';

import { Keepalive } from '@ng-idle/keepalive';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { AuthService } from 'src/app/core/auth.service';
import { ShareButtonComponent } from './share-button/share-button.component';
import { NbDialogService } from '@nebular/theme';
import { DialogShareAgentComponent } from './dialog-share-agent/dialog-share-agent.component';
import { CustomCellRenderersService } from './custom-cell-renderers.service';
import { CustomCellComparatorsService } from './custom-cell-comparators.service';


@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.scss']
})
export class HomeNewComponent implements OnInit {

  title = 'app';
  defaultColDef = { 
    sortable: true, 
    enableCellChangeFlash: true  
  };
  columnDefs = [
      {resizable: true, headerName: "Delete Item", field: "deleteButton",cellRenderer: "deleteButtonRenderer", colId: "deleteButton", width: 180 },
      {resizable: true, headerName: "Share Item", field: "shareButton",cellRenderer: "shareButtonRenderer", colId: "shareButton", width: 180 },
      {resizable: true, headerName: 'Hostname', field: 'hostname'},
      {resizable: true, headerName: 'IP Addresses', field: 'ipAddresses', cellRenderer: this.renderer.ipAddressRenderer, width:110},
      {resizable: true, headerName: 'firebase Instance', field: 'firebaseInstance'},
      {resizable: true, headerName: 'OS', field: 'os', width:110},
      {resizable: true, headerName: 'OSLastUpdated', field: 'hostOSLastUpdated', cellRenderer: this.renderer.osLastUpdated, comparator: this.comparators.osLastUpdatedComparator},
      {resizable: true, headerName: 'OS Boot Time', field: 'osBootTime', suppressSizeToFit:true, cellRenderer: this.renderer.osBootTime, comparator: this.comparators.osBootTimeComparator},
      {
        headerName: "Drive Free Space",
        children: [
          {headerName: '0', field: 'drive0FreeSpace', cellRenderer: this.renderer.freeSpaceInDrive, comparator: this.comparators.freeSpaceComparator },
          {headerName: '1', field: 'drive1FreeSpace', cellRenderer: this.renderer.freeSpaceInDrive, comparator: this.comparators.freeSpaceComparator },
          {headerName: '2', field: 'drive2FreeSpace', cellRenderer: this.renderer.freeSpaceInDrive, comparator: this.comparators.freeSpaceComparator}    
        ]
      },
      {
          headerName: "CPU %",
          children: [
              {headerName: "Period 10", field: "cpuPeriod10", cellRenderer:this.renderer.cpuPeriodRenderer, comparator: this.comparators.cpuPeriodComparator},
              {headerName: "Period 90", field: "cpuPeriod90", cellRenderer:this.renderer.cpuPeriodRenderer, comparator: this.comparators.cpuPeriodComparator}
          ]
      },
      {
        headerName: "Disk KB/s (60 Min)",
        children: [
            {headerName: "READ", field: "diskReadPeriod60", cellRenderer:this.renderer.diskIOPeriodRenderer},
            {headerName: "WRITE", field: "diskWritePeriod60", cellRenderer:this.renderer.diskIOPeriodRenderer}
        ]
      },
      {
        headerName: "Disk KB/s (120 Min)",
        children: [
            {headerName: "READ", field: "diskReadPeriod120", cellRenderer:this.renderer.diskIOPeriodRenderer},
            {headerName: "WRITE", field: "diskWritePeriod120", cellRenderer:this.renderer.diskIOPeriodRenderer}
        ]
      },
      //{headerName: 'AgentLastReported', field: 'agentLastReported' },
      {resizable: true, headerName: 'Time Since Reported', field: 'timeSinceCalledHome', cellRenderer: this.renderer.timeSinceLastReportedRenderer, comparator: this.comparators.minutesComparator},
      {resizable: true, headerName: 'Agent Version', field: 'agentVersion'},
      {resizable: true, headerName: 'Urgency Value', field: 'urgencyValue'}
      ];

  rowData = [
  ];

  context = { componentParent: this };
  frameworkComponents = {
    deleteButtonRenderer: DeleteButtonComponent,
    shareButtonRenderer: ShareButtonComponent
  };


  private groupDefaultExpanded;
  private getDataPath;
  private autoGroupColumnDef;
  private gridColumnApi;
  quickSearchValue: string = '';
  gridApi: any;
  idleState: string;
  timedOut: boolean;
  lastPing?: Date = null;
  subscribableFirestore: any;
  agentsReportingSincePageRefresh = new Set();
  userID: string = "";
  userRole: any;
  hitsForTimeFrame: number = 0;
  sessionStartTime = new Date();
  minutesSinceSessionStart: number;
  


  
  constructor(private renderer: CustomCellRenderersService, private comparators: CustomCellComparatorsService, private afs: AngularFirestore,private idle: Idle, private keepalive: Keepalive, private auth: AuthService, private dialogService: NbDialogService) {
    let now = moment(); 
    this.groupDefaultExpanded = -1;
    this.getDataPath = function(data) {
      return data.orgHierarchy;
    };
    this.autoGroupColumnDef = {
      headerName: "Organisation Hierarchy",
      cellRendererParams: { suppressCount: true }
    };
    


    //Timeout and Idle Settings START
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(300);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'Connected';
    });
    idle.onTimeout.subscribe(() => {
      this.subscribableFirestore.unsubscribe();
      this.idleState = 'You are no longer subscribed to Firestore Database!';
      this.timedOut = true;
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    
    this.reset();
    //Timeout and Idle Settings END
  }

  reset() {
    this.idle.watch();
    if(this.subscribableFirestore) this.populateTable();
    this.idleState = 'Connected';
    this.timedOut = false;
  }

  ngOnInit() {
    this.auth.user.subscribe(user=>{
      this.userID = (user.uid);
      this.userRole = (user.role);

      if(this.userRole != "SuperAdmin"){
        console.log('share');
        this.gridColumnApi.setColumnVisible('shareButton', false);
        this.gridColumnApi.setColumnVisible('deleteButton', false);
      }
      
      this.populateTable();
    })
    

  }

  /**
   *  Gets data from firebase and populates the table
   */
  populateTable() {

    if(this.userRole == "SuperAdmin"){
      var agentCollectionQueryReference = this.afs.collection('agent', ref => ref.orderBy('AgentLastReported','asc'));
    }else{
      var agentCollectionQueryReference = this.afs.collection('agent', ref => ref.where('authorizedUsers','array-contains',this.userID).orderBy('AgentLastReported','asc'));
    }
    
      this.subscribableFirestore = agentCollectionQueryReference.valueChanges().subscribe(docs=>{
      //console.log(docs);
      var newData = [];
      for(let i =0; i < docs.length; i++){
        try {
          var currentRow = {};
        
        currentRow['agentID'] = docs[i]['AgentID']; 
        currentRow['id'] = docs[i]['AgentID']; 

        
        currentRow['agentLastReported'] = docs[i]['AgentLastReported'].toDate(); 
        
        const diffInMilliseconds = new Date().getTime() - docs[i]['AgentLastReported'].toDate().getTime(); // 10800000
        currentRow['timeSinceCalledHome'] = (diffInMilliseconds/60000).toFixed(); 
        
        //currentRow['drives'] = docs[i]['HostDataPoints']['Drives']; 
        
        currentRow['hostname'] =  docs[i]['HostDataPoints']['HostName'];

        currentRow['agentVersion'] =  docs[i]['AgentVersion'];
        
        //HostOSLastUpdated added to Row
        if(docs[i]['HostDataPoints']['HostOSLastUpdated']){
          currentRow['hostOSLastUpdated'] =  docs[i]['HostDataPoints']['HostOSLastUpdated'].toDate() + "";
        }
        
        //SystemBootTime added to Row
        if(docs[i]['HostDataPoints']['SystemBootTime']){
          currentRow['osBootTime'] =  docs[i]['HostDataPoints']['SystemBootTime'].toDate()+"";
        }

        //DISKIO added to Row
        let diskIO = docs[i]['HostDataPoints']['DiskIO']
        if(diskIO){
          let diskRead60 = 0, diskRead120 = 0, diskWrite60 = 0, diskWrite120 = 0;

          diskIO.forEach(function(element) {

            if(element['Period']== 60 && (element['Label']+"").includes('Read')){
              if(element['Average'] > diskRead60) diskRead60 = element['Average'].toFixed(0);
              currentRow['diskReadPeriod60'] = diskRead60;
            }

            if(element['Period']== 60 && (element['Label']+"").includes('Write')){
              if(element['Average'] > diskWrite60) diskWrite60 = element['Average'].toFixed(0);
              currentRow['diskWritePeriod60'] = diskWrite60;
            }

            if(element['Period']== 120 && (element['Label']+"").includes('Read')){
              if(element['Average'] > diskRead120) diskRead120 = element['Average'].toFixed(0);
              currentRow['diskReadPeriod120'] = diskRead120;
            }

            if(element['Period']== 120 && (element['Label']+"").includes('Write')){
              if(element['Average'] > diskWrite120) diskWrite120 = element['Average'].toFixed(0);
              currentRow['diskWritePeriod120'] = diskWrite120;
            }
            
          });

        }

        //IP Addresses added to Row
        let adapters = docs[i]['HostDataPoints']['Adapters'];
        if(adapters){
          
          var ipAddresses = "";
          adapters.forEach(function(element) {
            ipAddresses+= element['IP_Address']+ ", ";
          });
          currentRow['ipAddresses'] =  ipAddresses;
        }

        let hostOS = docs[i]['HostDataPoints']['OS'];
        if(hostOS) currentRow['os'] = hostOS;

        let drives = docs[i]['HostDataPoints']['Drives'];
        if(drives){
          var drive0FreeSpace= undefined, drive1FreeSpace = undefined, drive2FreeSpace = undefined;
          var driveStartIndex = 0;

          //START-Eliminates any drive that is A: or a CDRom
          var tempDrives = [];
          drives.forEach(function(element) {
            if((element.Drive==="A:\\") || (element.DriveType==="CDRom")){
            }else{
              tempDrives.push(element);
            }           
          });
          drives = tempDrives;
          //END-Eliminates any drive that is A: or a CDRom

          if(drives[driveStartIndex]) drive0FreeSpace = drives[driveStartIndex].FreeSpace;
          if(drives[driveStartIndex + 1]) drive1FreeSpace = drives[driveStartIndex + 1].FreeSpace;
          if(drives[driveStartIndex + 2]) drive2FreeSpace = drives[driveStartIndex + 2].FreeSpace;
          if(!isNaN(drive0FreeSpace)) currentRow['drive0FreeSpace'] = (drive0FreeSpace/(1024*1024*1024)).toFixed(2);
          if(!isNaN(drive1FreeSpace)) currentRow['drive1FreeSpace'] = (drive1FreeSpace/(1024*1024*1024)).toFixed(2); 
          if(!isNaN(drive2FreeSpace)) currentRow['drive2FreeSpace'] = (drive2FreeSpace/(1024*1024*1024)).toFixed(2);
          
        }

        let cpuUtil = docs[i]['HostDataPoints']['CPUUtil'];
        if(cpuUtil){
          cpuUtil.forEach(function(element) {
              if(element['Period']==10 && element['Average']) currentRow['cpuPeriod10'] = (element['Average']).toFixed(2);
              if(element['Period']==90 && element['Average']) currentRow['cpuPeriod90'] = (element['Average']).toFixed(2);
          });
        }

        currentRow['firebaseInstance'] = docs[i]['cloudFunctionInstance'];
                

        //all data has been added to currentRow object

        var rowNode = this.gridApi.getRowNode(currentRow['id']);
        if(this.rowData.length==0){
          //Push to new data if no data exists in the table yet
          newData.push(currentRow);
        } else if(!rowNode){
          //if rowNode is null then the row is new to the db and needs to be added 
          this.gridApi.updateRowData({ add: [currentRow] });
          
        } else{
          //If table is already populated, use the row ID to update data

          
          
          //Flash the row ONLY if its a live agent
          if(!(JSON.stringify(rowNode.data)===JSON.stringify(currentRow)) && currentRow['timeSinceCalledHome']==0){
            this.gridApi.flashCells({
              rowNodes: [rowNode]
            });
            this.agentsReportingSincePageRefresh.add(currentRow['id']);
            this.hitsForTimeFrame++;
            var diff = Math.abs(new Date().getTime() - this.sessionStartTime.getTime());
            this.minutesSinceSessionStart = ((diff/1000)/60);
          }
          //Update the row
          this.gridApi.updateRowData({ update: [currentRow] });
          //rowNode.setData(currentRow);
          
        }
      
          
        } catch (error) {
          console.log(error)
        }
      }
      if(this.rowData.length==0){
        this.rowData = newData;  
      }
      this.gridApi.refreshClientSideRowModel('filter');
      //this.subscribableFirestore.unsubscribe();
      
    });
  }

  deleteItem(ID) {
    this.afs.collection("agent").doc(ID).delete();
    let nodeToDelete = (this.gridApi.getRowNode(ID));
    this.gridApi.updateRowData({ remove: [nodeToDelete] });

  }

  shareItem(ID) {
    this.dialogService.open(DialogShareAgentComponent, {
      context: {
        agentID: ID
      }
      },
    );
  }

  

  

  //Supporting Methods for the ag-table 
  getRowNodeId(data) {
    return data.id;
  };

  onGridReady(params){
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;

    console.log('Grid Ready');
  }

  onFirstDataRendered(param){
    console.log('First Data Entered');
    this.autoSizeAllColumns();
  }

  autoSizeAllColumns() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      if(column.colId != 'ipAddresses' && column.colId != 'os') allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  
  

  
}
