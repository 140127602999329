import { Injectable } from '@angular/core';
import * as moment from 'moment'; 

@Injectable({
  providedIn: 'root'
})
export class CustomCellRenderersService {

  constructor() { }

  //Custom Cell Renderers
  osLastUpdated(params){
    let days =moment(new Date()).diff(Date.parse(params.value), 'days');
    
    if(days > 90){
      return '<strong style="color: red;">'+days+' days</strong>';
    }else if(params.value){
      return days + ' days';
    }else{
      return; 
    }
    
  }

  osBootTime(params){
    let days =moment(new Date()).diff(Date.parse(params.value), 'days');
    
    if(params.value){
      return days + ' days';
    }else{
      return; 
    }
    
  }


  freeSpaceInDrive(params) {
    var gb = Number((params.value+""));
    if (gb===0.00) gb=null;

    if(gb < 5 && gb > 0){
      return '<strong style="color: red;">'+params.value+' GB</strong>';
    }else if(gb){
      return params.value+" GB";
    }else{
      return;
    }
  }

  cpuPeriodRenderer(params) {
    let highlightThreshhold = 0;

    if(params.colDef['field']=="cpuPeriod10") highlightThreshhold = 90;
    if(params.colDef['field']=="cpuPeriod90") highlightThreshhold = 85;

    var cpu = Number((params.value+""));
    if (cpu===0.00) cpu=null;

    if(cpu > highlightThreshhold && cpu > 0){
      return '<strong style="color: red;">'+params.value+'</strong>';
    }else if(cpu){
      return params.value;
    }else{
      return;
    }
  }

  diskIOPeriodRenderer(params) {
    
    var diskIO = Number((params.value+""));
    if (diskIO===0.00) diskIO=null;

    if(diskIO){
      return (diskIO/1000).toFixed(0);
    }else{
      return;
    }
  }

  timeSinceLastReportedRenderer(params) {
    var minutes = Number(params.value+"");
    if(minutes > 2){
      return '<strong style="color: red;">'+params.value+' min</strong>';
    }else{
      return params.value+" min";
    }
  }

  ipAddressRenderer(params) {
    if(!params.value) params.value = "";
    return (params.value+"");
  }
}
