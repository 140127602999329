import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbCardModule, NbTreeGridModule, NbButtonModule, NbDialogModule, NbInputModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { WebsocketService } from './services/websocket.service';
import { ChatService } from './services/chat.service';
import { LoginComponent } from './pages/login/login.component';
import { CoreModule } from './core/core.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { HomeNewComponent } from './pages/home-new/home-new.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DeleteButtonComponent } from './pages/home-new/delete-button/delete-button.component';
import { ShareButtonComponent } from './pages/home-new/share-button/share-button.component';
import { DialogShareAgentComponent } from './pages/home-new/dialog-share-agent/dialog-share-agent.component'; 



const config = {
  apiKey: "AIzaSyCPbc_4dwugViOTAvbOpL4A7LF5dKqO_E0",
  authDomain: "helloworld-4fda9.firebaseapp.com",
  databaseURL: "https://helloworld-4fda9.firebaseio.com",
  projectId: "helloworld-4fda9",
  storageBucket: "helloworld-4fda9.appspot.com",
  messagingSenderId: "145348215766",
  appId: "1:145348215766:web:b136b5addaf6248a"
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HomeNewComponent,
    DeleteButtonComponent,
    ShareButtonComponent,
    DialogShareAgentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    CoreModule,
    AngularFireModule.initializeApp(config),
    FormsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbCardModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbButtonModule,
    HttpClientModule,
    NbInputModule,
    NbDialogModule.forRoot(),
    AgGridModule.withComponents([]),
    NgIdleKeepaliveModule.forRoot()

  ],
  entryComponents: [DeleteButtonComponent, ShareButtonComponent, DialogShareAgentComponent],
  providers: [
    WebsocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
