import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './core/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { HomeNewComponent } from './pages/home-new/home-new.component';


const routes: Routes = [
  
  {
      path: '',
      canActivate: [AuthGuard],
      component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    
  },
  {
    path: 'new',
    canActivate: [AuthGuard],
    component: HomeNewComponent,
    
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
